import React from 'react';

export interface SectionTitleProps {
  title: string,
  useDivider ?: boolean,
  className ?:string,
  size?: "normal" | "small" | "large" | "medium"
}
export function SectionTitle(props:SectionTitleProps)
{
  const { title="", className="", useDivider=true, size="large"} = props;
  let textClassName="";
  switch(size)
  {
    case "large":
    {
      textClassName="text-xl md:text-3xl";
      break;
    }
    case "medium":
    {
      textClassName="text-lg md:text-2xl";
      break;
    }
    case "normal":
    {
      textClassName="text-base md:text-xl";
      break;
    }
    case "small":
    {
      textClassName="text-sm md:text-base";
      break;
    }
  }
  return(<div className={'flex flex-col items-center justify-center '+className}>
    <h2 className={`text-secondary my-2 first-letter:uppercase ${textClassName}`}>{title}</h2>
    {useDivider?<hr className={"w-32 md:w-64 m-2 mb-4"} />:null}
  </div>)
}