import React from 'react';
import { Link } from 'react-router-dom';

export interface CaseStudyThumbnailProps {
  item: CaseStudyData,
}
export interface CaseStudyData {image:string, title:string, subtitle?:string, description:string, path?:string, content?:Array<string>};

export function CaseStudyThumbnailContent(props:any)
{
  const { image="", title="", description="", subtitle="" } = props;
  return(<div className='w-full h-full flex flex-row flex-nowrap justify-start bg-darkShadow'>
    <div className="basis-1/2 shrink flex flex-col justify-center content-center items-center bg-dark px-1">
      <img src={image} className="inline-block shrink max-h-32"/>
    </div>
    <div className='h-full basis-1/2 grow bg-darkShadow text-light py-2 px-4 md:py-4 md:pl-8 shrink-0'>
      <h4 className='font-semibold text-sm md:text-base text-center underline-offset-2 group-hover:underline group-hover:text-secondary'>{title}</h4>
      {subtitle?<h5 className='font-semibold text-xs md:text-sm text-center group-hover:text-secondary'>{subtitle}</h5>:null}
      <p className='text-xs md:text-sm text-light text-center hidden group-hover:inline-block'>{description}</p>
    </div>
  </div>)
}

export function CaseStudyThumbnail(props: CaseStudyThumbnailProps) {
  const { item } = props
  return (<Link className={"border border-dark inline-block min-h-24 md:min-h-48 w-64 sm:w-96 md:w-[32rem] group shadow-lg shadow-black m-1 md:m-4 transition-transform hover:shadow hover:scale-[1.01] hover:cursor-pointer"} key={item.title} to={item.path||""} >
    <CaseStudyThumbnailContent {...item}/>
  </Link>);
}

export default CaseStudyThumbnail
