import React from "react";
import debounce from 'debounce';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { createSubscribeNewsletterRequestFunction, createContactUsRequestFunction } from './utils/util';
// components
import Layout from "./components/layout/Layout";
import { routes,RouteItem } from "./routes";

export const APP_TITLE = "Notoros, Inc.";
const newsletterApiRoute = `${window.location.origin}/api/newsletter`;
const contactUsApiRoute = `${window.location.origin}/api/contact`;
const NewsletterSignupFunction = debounce(createSubscribeNewsletterRequestFunction(newsletterApiRoute), 10000, true);
const ContactUsFunction = debounce(createContactUsRequestFunction(contactUsApiRoute), 10000, true);

// default component
function DefaultComponent(props:any){
  return(<div>{`No Component Defined.`}</div>)
};

function App() {


  return (
    <React.Fragment >
      <Helmet>
        <title>{APP_TITLE}</title>
      </Helmet>
      <BrowserRouter>
        <_App newsletterSignupFunction={NewsletterSignupFunction}  contactUsFunction={ContactUsFunction}/>
      </BrowserRouter>
    </React.Fragment>
  );
}

function filterHidden(r:RouteItem, i:number)
{
  return(!r.hidden)
}
function mapSubroutes(r: RouteItem, i:number)
{
  const _r = {...r};
  if(Array.isArray(r.subRoutes)&&r.subRoutes.length > 0)
  {
    _r.subRoutes = r.subRoutes.filter(filterHidden).map((sr, i)=>{
      return({...sr});
    });
  }
  return(_r);
}
const headerLinks = routes.filter(filterHidden).map(mapSubroutes);

export function _App(props:any)
{
  const renderedRoutes = routes.map((route: RouteItem, i:number) => {
    const RouteComponent = route.component || DefaultComponent;
    return(
    /* for each route config, a react route is created */
    route.subRoutes ? route.subRoutes.map((item: RouteItem, j:number) => {
        const SubrouteComponent = item.component || DefaultComponent;
        return(<Route
          key={`${i}-${j}`}
          path={`${item.path}`}
          element={<SubrouteComponent {...props} />}
        />);
      }) :
      <Route
        key={`${i}`}
        path={`${route.path}`}
        element={<RouteComponent {...props} />}
      />
  )});

  return(<Layout {...props} headerLinks={headerLinks}>
    <Routes>
      {renderedRoutes}
    </Routes>
  </Layout>);
}

export default App;