import React, { MouseEvent, PropsWithChildren } from "react";

export function Button(props:any)
{
  const {
    onClick = ()=>console.log("Default Button OnClick Handler"), className="", children=[], size="normal",
    ...otherProps
  } = props;
  function handleClick(e:MouseEvent)
  {
    e.stopPropagation();
    e.preventDefault();
    onClick();
    return;
  }
  let fullClassName = " whitespace-nowrap hover:cursor-pointer text-sm font-semibold uppercase rounded-sm tracking-widest ";
  switch(size)
  {
    case "normal":
    {
      fullClassName += "py-2 px-4";
      break;
    }
    case "wide":
    {
      fullClassName += "py-4 px-4";
      break;
    }
    case "thin":
    {
      fullClassName += "py-2 px-2";
      break;
    }
    case "icon":
    {
      fullClassName += "py-0 px-0 bg-transparent";
      break;
    }
    default: {
      //nothing
    }
  }
  fullClassName += " "+className;
  return(<button className={fullClassName} onClick={handleClick} {...otherProps}>
    {children}
  </button>)
}

export function IconButton(props:any)
{
  const { onClick, className="", size="normal", variant="primary", children, ...otherProps } = props;
  let fullClassName = className+" ";

  return(<Button size={"icon"} className={fullClassName} onClick={onClick} {...otherProps}>
    {props.children}
  </Button>)
}

export interface ActionButtonProps extends PropsWithChildren {
  onClick ?: ()=>any,
  variant ?: "primary" | "secondary",
  size ?: "normal" | "wide" | "thin" | "icon",
  className ?:string,
  download ?: string,
  href ?: string,
}
export function ActionButton(props:ActionButtonProps)
{
  const { onClick, className="", size="normal", variant="primary", children, ...otherProps } = props;
  let fullClassName = className+" ";
  switch(variant)
  {
    case "primary":
    {
      fullClassName += " bg-secondary hover:bg-vibrant text-dark hover:text-light";
      break;
    }
    case "secondary":
    {
      fullClassName += " bg-vibrantDull hover:bg-vibrant text-dark hover:text-light";
      break;
    }
    default:{
      //Nothing
    };
  }
  return(<Button size={size} className={fullClassName} onClick={onClick} {...otherProps}>
    {props.children}
  </Button>)
}