//Helpers for HTML5 canvases

export function getCanvas(id="canvas-element"): HTMLCanvasElement|null
{
  const canvas = document.getElementById(id);
  if(canvas)
  {
    return(canvas as HTMLCanvasElement);
  }
  else
  {
    return(null);
  }
}

export function getCanvasContext(canvas: HTMLCanvasElement)
{
  const ctx = canvas.getContext('2d');
  return(ctx);

}

export function getCanvasDims(canvas: HTMLCanvasElement): [number, number]
{
  const canvasWidth = canvas.offsetWidth;
  const canvasHeight = canvas.offsetHeight+1;
  return([canvasWidth, canvasHeight]);
}

export function setupCanvas(canvas: HTMLCanvasElement, bgColor:any="rgba(255,255,255,1)")
{

  const ctx = getCanvasContext(canvas);
  if(ctx)
  {
    const [cW, cH] = getCanvasDims(canvas);
    canvas.height = cH;
    canvas.width = cW;
    ctx.fillStyle= bgColor;
    ctx.fillRect(0, 0, cW, cH);
  }
  return;
}

export function drawCircle(
  xCoord:number, yCoord:number, radius:number,
  ctx:any,
  fill=null
){
  ctx.beginPath();
  const startAngle = 0;
  const endAngle = Math.PI*2;
  ctx.arc(xCoord, yCoord, radius, startAngle, endAngle);
  if(fill)
  {
    ctx.fillStyle= fill;
    ctx.fill();
  }
  else{
    ctx.stroke();
  }
}