import React from "react";

export function Section(props:any)
{
  const { className ="" } = props;
  return(<div className={`py-2 px-2 bg-dark text-center ${className}`}>
    {props.children}
  </div>)
}
export default Section;
