import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DropdownMenu } from "./DropdownMenu";

export interface AppNavigationLinkProps {
  id: string | number,
  title: string | React.ReactElement | React.ReactElement[],
  tooltip ?: string,
  path ?: string,
  subheading?:string | undefined,
  subRoutes ?: Array<{
    id: string | number,
    title: string | React.ReactElement | React.ReactElement[],
    subheading:string,
    path ?: string,
  }>
};

export interface AppNavigationLinksProps {
  links ?: Array<AppNavigationLinkProps>,
  className ?: string,
  vertical ?: boolean,
};

export function AppNavigationLinks(props:AppNavigationLinksProps)
{
  const { links=[], vertical=false, className="" } = props;
  const fullClassName = "bg-inherit flex justify-start "+ (vertical?"flex-col":"flex-row") + " " + className;

  const [openMenus, setOpenMenus] = useState<Array<string>>([]);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const clickedElement = event.target as HTMLElement;
      const clickedMenu = clickedElement.closest(".dropdown-menu");
      const clickedLink = clickedElement.closest(".dropdown-link");

      if (clickedMenu && clickedLink) {
        // Click occurred inside a dropdown menu, so do nothing
        return;
      }

      setOpenMenus([]);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleMenuClick = (id: string) => {
    if (openMenus.includes(id)) {
      setOpenMenus(openMenus.filter(i => i !== id));
    } else {
      setOpenMenus([id]);
    }
  }

  return(
    <ul className={fullClassName}>
      {links.map((l: AppNavigationLinkProps, i:number)=>{
        const id = i.toString();
        const isMenuOpen = openMenus.includes(id);
        return(
          <li key={id} className="bg-inherit flex flex-col justify-center">
            <AppNavigationLink
              {...l}
              id={id}
              menuOpen={isMenuOpen}
              onMenuClick={() => handleMenuClick(id)}
            />
          </li>
        );
      })}
    </ul>
  );
}



export function AppNavigationLink(props:AppNavigationLinkProps & { menuOpen: boolean, onMenuClick: () => void })
{
  const { id, title="", tooltip="", path="", subRoutes=[], menuOpen, onMenuClick } = props;

  if(subRoutes.length ==0 && title === "Docs") {
    return(<a rel="noopener noreferrer" href="https://notoros.github.io/introduction/" target="_blank" className={"rounded-sm px-2 align-middle text-base text-secondary hover:cursor-pointer hover:bg-white hover:bg-opacity-10 "}>{title}</a>)
  }
  else if(subRoutes.length == 0)
  {
    return(<Link to={path} className={"rounded-sm px-2 align-middle text-base text-secondary hover:cursor-pointer hover:bg-white hover:bg-opacity-10 "}>{title}</Link>)
  }
  else
  {
    return(<DropdownMenu
      id={id}
      open={menuOpen}
      setOpen={onMenuClick}
      title={title}
      links={subRoutes
        .filter((r) => typeof r.path === "string")
        .map((r, i) => ({ id: r.id, text: r.title, link: r.path ?? "#", subheading: r.subheading || "" }))
      }
      className="z-30"
    />)
  }
}
