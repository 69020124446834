import React, { FC, ReactElement } from 'react';


// define css-in-js
// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {
//             flex: 1,
//             display: "flex",
//             flexDirection: "row",
//             justifyContent: "space-between",
//         },
//     })
// );

export function Developer(): ReactElement{
    return (
        <>
            <div style={{ height: '900px', display: 'block', padding: '20px' }}>
                <div>

                    Developer stuff goes here
                </div>
            </div>
        </>
    )
}

export default Developer;