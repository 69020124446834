import React, {useEffect} from 'react';
import {getCanvas, getCanvasContext, getCanvasDims, drawCircle, setupCanvas} from './canvasHelpers';

//PseudoRandom number generator
// source: https://stackoverflow.com/a/47593316
function mulberry32(a:number) {
  return function() {
    var t = a += 0x6D2B79F5;
    t = Math.imul(t ^ t >>> 15, t | 1);
    t ^= t + Math.imul(t ^ t >>> 7, t | 61);
    return ((t ^ t >>> 14) >>> 0) / 4294967296;
  }
}


const backgroundColor = "rgb(14,17,126)";
const circleFillDarkest = "rgb(20,88,159)";
const circleFillMedium = "rgb(21,102,184)";
const circleFillBrightest = "rgb(25,124,217)";

function drawPackedCircles(
  xOrigin:number,
  yOrigin:number,
  numRows:number,
  numCols:number,
  radius:number,
  margin=1,
  canvas:HTMLCanvasElement,
  fillFunction=(x:number,y:number)=>null
)
{
  if(canvas)
  {
    const ctx = getCanvasContext(canvas);
    const [xDim, yDim] = getCanvasDims(canvas!);
    ctx!.lineWidth = 1;
    const circleFenceLength = getFenceSize(radius,margin);
    for(let colIndex=0; colIndex<=numCols; colIndex++)
    {
      //Iterate in the X direction
      let xBase = xOrigin + circleFenceLength*colIndex;
      const xCenter = xBase+circleFenceLength/2;
      if(xBase + circleFenceLength > xDim)
      {
        break;
      }
      for(let rowIndex=0; rowIndex<=numRows; rowIndex++)
      {
        //Iterate in the Y direction
        const yMod = colIndex%2==1? circleFenceLength/2:0;
        const yBase = yOrigin + circleFenceLength*rowIndex-yMod;

        const yCenter = yBase+circleFenceLength/2;
        if(yBase+circleFenceLength > yDim)
        {
          break;
        }
        drawCircle(xCenter, yCenter, radius, ctx, fillFunction(xCenter,yCenter));
      }
    }
  }
}

export function fillCanvasWithCircles(radius:number, margin=1, canvas:HTMLCanvasElement, fillFunction=(x:number, y:number):null|any=>null)
{
  const [cW, cH] = getCanvasDims(canvas);
  const fenceSize = getFenceSize(radius,margin);
  const numRows = Math.floor(cH / fenceSize) + 1;
  const numCols = Math.floor(cW / fenceSize) + 1;

  drawPackedCircles(0, 0, numRows, numCols, radius, margin, canvas, fillFunction);
}

function getFenceSize(radius:number, margin:number)
{
  return(2*(radius+margin));
}

function oneOfThreeFillFunction(xCoord:number, yCoord:number)
{
  const mulberry = mulberry32(xCoord*yCoord);
  const sum = Math.floor(100*mulberry());
  switch(sum%3)
  {
    case 0:{return(circleFillBrightest);}
    case 1:{return(circleFillDarkest);}
    case 2:{return(circleFillMedium);}
    default: {return(null);}
  }
}

export function setupBaseCanvas(baseCanvas: HTMLCanvasElement)
{
  setupCanvas(baseCanvas, backgroundColor);
  fillCanvasWithCircles(5,2, baseCanvas, oneOfThreeFillFunction);
  return(baseCanvas);
}

export function setupGlowCanvas(glowCanvas: HTMLCanvasElement)
{
  const glowCtx = getCanvasContext(glowCanvas);
  const [cW, cH] = getCanvasDims(glowCanvas);
  const glow = glowCtx!.createRadialGradient(cW/2, cH/2, 0, cW/2, cH/2, Math.max(cW/2, cH/2));
  glow.addColorStop(0, "rgba(50,70,50, 0.5");
  glow.addColorStop(0.4, "rgba(30,50,30, 0.5");
  glow.addColorStop(1, "rgba(10,10,10, 0.8");
  setupCanvas(glowCanvas, glow);
}

export function DotsBackground(props:any)
{
  // const classes = useStyles();
  const {height, width, className,style={}, ...otherProps} = props;
  const id = Date.now()+Math.random()*100;
  const baseId = `${id}_dots_canvas_base`;
  const topId = `${id}_dots_canvas_top`;

  // Base Background Canvas
  const baseCanvas :HTMLCanvasElement = <canvas className={"z-0 absolute left-0 top-0 m-0 h-full w-full"} id={baseId} /> as any as HTMLCanvasElement;
  // Glow Canvas
  const glowCanvas :HTMLCanvasElement = <canvas className={"z-0 absolute left-0 top-0 m-0 h-full w-full"} id={topId}/> as any as HTMLCanvasElement;

  function setupCanvases(){
    let bCanvas = getCanvas(baseId);
    let tCanvas = getCanvas(topId);
    if(bCanvas && tCanvas)
    {
      setupBaseCanvas(bCanvas);
      setupGlowCanvas(tCanvas);
    }
  }
  useEffect(()=>{
    setupCanvases();
    function resizeListener()
    {
      setupCanvases();
    }
    window.addEventListener('resize',resizeListener);
    return(()=>{
      //cleanup
      window.removeEventListener('resize', resizeListener);
    })
  }, []);

  return(<div className={"relative "+className } style={{height, width}} {...otherProps}>
    {baseCanvas}
    {glowCanvas}
    <div className='relative'>{props.children}</div>
  </div>);
}

export function DotsCanvas(props:any)
{
   // Base Background Canvas
   const baseCanvas :HTMLCanvasElement = <canvas {...props}/> as any as HTMLCanvasElement;
   setupBaseCanvas(baseCanvas);
   setupGlowCanvas(baseCanvas);
   return(baseCanvas);
}