import React, { ReactElement, useEffect, useState, useRef} from "react";
import NewsletterSection from "../../components/sections/NewsletterSection";
import FeaturesSection from "../../components/sections/FeaturesSection";
import { ZeroTrustPillarsSection } from "../../components/sections/ZeroTrustPillarsSection";
import { UseCasesSection} from "../../components/sections/UseCasesSection";
import VerticalTimeLine from "../../components/sections/VerticalTimeLine";
import { DotsBackground } from '../../components/backgrounds/dotsBackground';
import { TypewritingText } from "./TypewritingBanner copy";
import CaseStudiesSection from "../../components/sections/CaseStudiesSection";

const BannerWordEntries: Array<string> = [
  "For Total Confidence",
  "For Advanced Security",
  "For Improved Automation",
  "For Enhanced Data Fusion",
  "For Streamlined Revenue",
];

export function RotatingBanner(props:any)
{
  const [bannerWords, setBannerWords]=useState<number>(0);
  const timerRef = useRef<any>(null);
  function updateTimer()
  {
    if(timerRef.current)
    {
      clearTimeout(timerRef.current);
    }
    setTimeout(()=>{
      setBannerWords((n:number)=>{
        if(BannerWordEntries.length - 1 < n+1)
        {
          return(0)
        }
        else
        {
          return(n+1)
        }
      });
      updateTimer();
    }, 7000);
  }
  useEffect(()=>{
    updateTimer();
    return(()=>{
      if(timerRef.current)
      {
        clearTimeout(timerRef.current);
      }
    });
  },[])
  return(<div className="flex flex-col justify-center content-center items-center bg-black bg-opacity-40">
    <h1 className="text-light inline-block shrink font-semibold text-3xl md:text-6xl py-2 md:py-4 mr-2 text-right">Zero Trust </h1>
    <span className="inline-block">
      <TypewritingText words={BannerWordEntries[bannerWords]} className={"text-left text-light border-r-light font-semibold "}/>
    </span>
  </div>);
}

function Home(props:any): ReactElement {
  const {newsletterSignupFunction = (email:string)=>{console.log("Home Newsletter Signup")}} = props;

  return (<div>
    <style>
      @import
      url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap')
    </style>

    <DotsBackground
      className={"py-12 md:py-24 border-b border-b-dark"}
    >
      <RotatingBanner/>
      {/* <HeroBanner content="Total Confidence Using Zero-Trust"/>
      <TypewritingBanner words={["BETANET", "IS", "HERE!"]} /> */}
    </DotsBackground>
    <ZeroTrustPillarsSection/>
    <CaseStudiesSection/>
    <UseCasesSection/>
    <NewsletterSection
      size="medium"
      bgImage=""
      bgImageOpacity={1}
      title="Stay in the know"
      subtitle="Receive our latest articles and feature updates"
      buttonText="Become an Nsider"
      inputPlaceholder="Enter your email"
      subscribedMessage="You are now subscribed!"
      newsletterSignupFunction = {newsletterSignupFunction}
    />
  </div>);
};

export default Home;
