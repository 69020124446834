import React from "react";
import { LargeCarousel } from "../../components/carousel/LargeCarousel";
import { sortedUseCases, useCasesByIndustry } from "../../appData/useCaseData";
import { SectionTitle } from "../../components/layout/section/SectionTitle";
import SmallCarousel from "../../components/carousel/SmallCarousel";
import { useLocation } from "react-router-dom";

function camelCaseString(s:string)
{
  return(s.replace("_"," ").split(" ").map((v)=>{
    if(v.length > 0)
    {
      const [firstChar, ...rest] = v;
      v = firstChar.toUpperCase()+rest.join("");
    }
    return(v);
  }).join(" "));
}

export function IndustrySpecificUseCaseSection(props:any)
{
  const { title="", items =[] } = props;
  return(<div className="flex flex-col justify-start items-center">
    <SectionTitle title={title} />
    <SmallCarousel items={items} />
  </div>)
}

export function UseCasesHome(props:any) {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const selection = searchParams.get("selection");
  console.log(selection);

  return(<div className="flex flex-col justify-center px-4 md:px-16 bg-dark text-secondary">
    {
      (selection===null || selection ==="all")?<LargeCarousel items={sortedUseCases} />:<LargeCarousel items={useCasesByIndustry[selection]}/>
    }
    {
      Object.keys(useCasesByIndustry).filter((ind:string, i:number)=>{
        if(selection===null || selection ==="all")
        {
          return(true);
        }
        else
        {
          return(ind.toLowerCase().replace(" ","_")===selection)
        }
      }).map((ind:string, i:number)=>{
        return(<IndustrySpecificUseCaseSection title={camelCaseString(ind)} key={i} items={useCasesByIndustry[ind]} />)
      })
    }
  </div>);
}

export default UseCasesHome;