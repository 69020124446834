import React from "react";
import { SectionTitle } from "../layout/section/SectionTitle";
import { FeatureItem } from "./FeaturesSection";

export const zeroTrustPillarsData: Array<{title:string, body:string, image:string}> = [
  {
    title: "Identity",
    body: "Continuous Validation Proves The User Is Who They Claim To Be",
    image: "/images/icons/identification.svg",
  },
  {
    title: "Device",
    body: "Constant Monitoring Ensures Hardware Is Working As Intended",
    image: "/images/icons/cpuchip.svg",
  },
  {
    title: "Network",
    body: "Micro-Perimeter Architecture Minimizes Attack Surfaces",
    image: "/images/icons/serverstack.svg",
  },
  {
    title: "Application",
    body: "Granular Access Control Secures Deep Integrations",
    image: "/images/icons/commandline.svg",
  },
  {
    title: "Data",
    body: "Encrypted and Distributed Data Promotes High Availability",
    image: "/images/icons/circlestack.svg",
  }
];

export function ZeroTrustPillarsSection(props:any){
  return (<div className="bg-dark flex flex-col items-center py-4 md:px-8 pb-8">
    <SectionTitle title={"Elements of Zero Trust"}/>
    <div className={"flex flex-row justify-center content-start flex-wrap text-light pb-2"}>
      { zeroTrustPillarsData.map((item, key:number)=>{
        return(<FeatureItem {...item} key={key}/>);
      })}
    </div>
  </div>);
}
