import React, { useEffect, useRef, PropsWithChildren } from 'react';
import { setUpDocumentListener } from '../../utils/documentClickListener';
export interface DrawerProps extends PropsWithChildren {
  open ?: boolean,
  setOpen ?: (b:boolean)=>boolean,
  className ?: string,
};

export function Drawer(props:DrawerProps)
{
  const { open=false, setOpen=(o:boolean)=>true, className="" } = props;
  const ref = useRef<HTMLDivElement | null>(null);

  // Adds event listener for clicks outside of menu and closes drawer if open
  useEffect(()=>{
    return(setUpDocumentListener(ref, ()=>setOpen(false)));
  },[]);

  return(<div ref={ref} className={'z-20 overflow-x-hidden ease-linear transition-width fixed right-0 h-full '+(open?"w-48":"w-0")}>
    <div className={"flex flex-col justify-start h-full w-48 overflow-hidden text-left p-2 " +className}>
      {props.children}
    </div>
  </div>);
}