import React from 'react';
import SmallCarousel from '../../components/carousel/SmallCarousel';
import { useCaseData } from "../../appData/useCaseData";
import { Link } from 'react-router-dom';

const Languages : {[key:string]:{[key:string]:string}} = {
  'en': {
    USE_CASE_SOLUTION_TITLE: "Solution",
    USE_CASE_PROBLEM_TITLE: "Problem",
    SEE_ALSO: "See Also"
  }
}

export function UseCaseContentSection(props:any)
{
  const { title="", content=[], citations=[]}= props;
  return(<div className={"my-4 md:px-12 xl:px-16 max-w-5xl"}>
    <h4 className="text-light text-left text-2xl font-semibold">{title}</h4>
    <hr style={{borderWidth: '1px', width: '100%', borderColor: '#eee', marginTop: '1rem', marginBottom: '2rem', borderStyle:"solid"}}/>
    {content.map((c:any, index:number)=><p key={index} className={"text-light text-sm md:text-base text-left my-1"}>{c}</p>)}
    <div className='my-2'>
      {citations.map((c:{text:string, link:string}, index:number)=><a href={c.link||""} target="_blank" key={index} className={"text-primary block text-sm md:text-base text-left my-1"}>[{(index+1).toFixed(0)}] {c.text}</a>)}
    </div>
  </div>)
}

export function UseCasesSeeAlsoSection(props:any)
{
  const { displayLanguage = "en", links=[] } = props;
  let seeAlsoArray:any[] = []

  links.forEach((link:any) => {
    let linkItem = useCaseData.find(item => item.route === link.link)
    if (linkItem) seeAlsoArray.push(linkItem)
  })

  return(<div className='max-w-5xl'>
    <div className={"my-4 md:px-12 xl:px-16"}>
      <h5 className="text-light text-left text-2xl font-semibold">{Languages[displayLanguage].SEE_ALSO}</h5>
      <div style={{height: '1px', width: '100%', backgroundColor: '#eee', marginTop: '1rem', marginBottom: '2rem'}}></div>
    </div>
    <SmallCarousel items={seeAlsoArray} />
  </div>);
}

export function UseCaseHeroBanner(props:any)
{
  const { image, title }=props;

  return(<div
    className='w-full bg-cover h-64 flex flex-col justify-center'
    style={{
      backgroundImage: `url(${image})`,
    }}
  >
    <TitleCutout title={title}/>
  </div>);
}

export function TitleCutout(props:any)
{
  const { title="" } = props;

  return(<div className={"flex flex-row items-center w-4/5 h-16 "}>
    <div className='bg-black bg-opacity-70 h-full grow basis-1/5'></div>
    <span className="bg-black bg-opacity-70 h-full basis-4/5 flex flex-col justify-center md:px-12 px-4">
      <h1 className={"md:text-3xl text-xl whitespace-nowrap font-bold text-light px-2 md:px-8"}>{title}</h1>
    </span>
    <span className={"md:w-24 w-12 h-full bg-black bg-opacity-70"} style={{clipPath:"polygon(0% 0%, 100% 0%, -1% 101%, -1% -1%)"}}></span>
  </div>)
}

export function UseCaseDetailsPage(props:any)
{
  const {
    displayLanguage = "en",
    useCaseDetails = {
      problem: [],
      solution: [],
      title: "",
      image: "",
      links: []
    }
  } = props;
  return (<React.Fragment>
    <UseCaseHeroBanner title={useCaseDetails.title} image={useCaseDetails.image}/>
    <div className="flex flex-col content-center items-center justify-between py-2 md:py-8 px-4 md:px-16">
      <UseCaseContentSection content={useCaseDetails.problem} title={Languages[displayLanguage].USE_CASE_PROBLEM_TITLE}/>
      <UseCaseContentSection content={useCaseDetails.solution} citations={useCaseDetails.citations} title={Languages[displayLanguage].USE_CASE_SOLUTION_TITLE}/>
      <UseCasesSeeAlsoSection links={useCaseDetails.links} />
    </div>
  </React.Fragment>);
}