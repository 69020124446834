import React, { KeyboardEvent } from 'react';

export function setUpDocumentListener(ref: React.MutableRefObject<Element | null>, onClickOut:()=>any=()=>true, onClickIn:()=>any=()=>true)
{
  const handleDocumentClick = (e:any)=>{
    if(ref.current)
    {
      if(!ref.current.contains(e.target))
      {
        onClickOut();
      }
      else
      {
        onClickIn();
      }
    }
  }
  document.addEventListener('click', handleDocumentClick, {capture: false});
  return(()=>{
    document.removeEventListener('click', handleDocumentClick, {capture: false});
    return;
  });
}