import React, { useState } from "react";
import { NewsletterSignupModal } from "../modals/NewsletterSignupModal";
import { ActionButton } from "../util/Buttons";

export function NewsletterSection(props:any) {
  const { newsletterSignupFunction } = props;

  const [modalOpen, setModalOpen] = useState(false);
  async function handleSubmit(email:string)
  {
    console.log(email);
    return(newsletterSignupFunction(email));
  }

  return (<div className="flex flex-row flex-wrap justify-center bg-darkShadow py-4 md:py-16">
      <span className="mx-2 md:mx-8">
        <h4 className="text-light text-4xl text-left">Stay in the know</h4>
        <p className="py-4 text-sm text-light text-left">Receive our latest articles and feature updates</p>
      </span>
      <span className="flex flex-col justify-center content-center"><ActionButton size="normal" variant="primary" className="m-2" onClick={()=>setModalOpen(!modalOpen)}>
        BECOME AN NSIDER
      </ActionButton></span>
      <NewsletterSignupModal open={modalOpen} onClose={()=>{setModalOpen(false)}} onSubmit={handleSubmit}/>
    </div>
  );
}

export default NewsletterSection;
