import React from 'react';
import ItemThumbnail from '../cards/ItemThumbnail';

interface SmallCarouselProps {
  items:any[]
}

export function SmallCarousel(props: SmallCarouselProps) {
  const { items=[] } = props;
  return (<div className='inline-flex flex-row flex-wrap justify-center'>
    {
      items.map((item, index:number) =>{
        return(<ItemThumbnail key={index} item={item}/>);
      })
    }
  </div>);
}

export default SmallCarousel
