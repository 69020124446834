import React from "react";
import { Link } from "react-router-dom";

import DocLinks from '../../appData/docsLinks';
import { Logo } from "./Logo";
import { SocialButtonsPanel } from "./Socials";

export const FOOTER_TEXT = `\u00A9 ${new Date().getFullYear()} Notoros, Inc.`;

export function Footer(props:any){
  return (<div className={"flex items-stretch flex-col bg-dark py-4"}>
    <div className={"px-2 md:px-8 "}>
      <div className={"flex flex-col md:flex-row justify-between items-center"}>
        <LogoPanel/>
        <MissionStatementPanel/>
        <div className={"flex flex-row justify-between my-2"}>
          <CompanyLinksPanel/>
          <LegalLinksPanel/>
        </div>
      </div>
    </div>
    <SocialButtonsPanel className="text-center my-4" spacing="loose"/>
    <div className={"text-darkShadow pb-2"}>
      {FOOTER_TEXT}
    </div>
  </div>);
};

export function LogoPanel(props:any)
{
  return(<div className={"mx-2 md:ml-4 md:mr-8 my-2 text-left"}>
    <Logo />
  </div>);
}

export function MissionStatementPanel(props:any)
{
  return(<div className={"flex flex-row md:flex-col justify-center items-center text-light text-left md:max-w-[16rem] mx-1 md:mx-4"}>
    {/* <h5 className={"mb-2 px-2 w-full font-semibold"}>Mission Statement</h5> */}
    <p className="text-xs sm:text-sm italic">"Notoros makes zero trust technology accessible"</p>
  </div>);
}

export interface FooterLinksPanelProps {
  title: string,
  links: Array<{
    text: string,
    link: string,
  }>
}
export function FooterLinksPanel(props:FooterLinksPanelProps)
{
  const { title ="", links =[] } = props;
  return(<div className={"flex justify-start flex-col text-left mx-4"}>
    <h5 className={"text-light mb-2 font-semibold"}>{title}</h5>
    <ul>
      {links.map(({text="", link=""}, key:number)=>{
        return(<li key={key}><Link className={"no-underline hover:underline text-secondary text-sm"} to={link} target='_blank' rel="noreferrer" onClick={() => window.open(link)}>{text}</Link></li>)
      })}
    </ul>
  </div>);
}

//{text: "Whitepaper", link: DocLinks.WHITEPAPER}, {text: "FAQ", link: DocLinks.FAQ}
export function CompanyLinksPanel(props:any)
{
  return(<FooterLinksPanel title="Company" links={[{text: "Contact Us", link: "/contact"}]} />);
}

export function LegalLinksPanel(props:any)
{
  return(<FooterLinksPanel title="Legal" links={[{text: "Privacy Policy", link: DocLinks.WEBSITE_PRIVACY_POLICY}, {text: "Acceptable Use", link: DocLinks.WEBSITE_ACCEPTABLE_USE}]} />);
}


export default Footer;
