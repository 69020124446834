import React from 'react';
import { IconButton } from '../util/Buttons';
import { RedditIcon, LinkedInIcon, TwitterIcon, TelegramIcon } from '../util/Icons';
// import RedditIcon from '@mui/icons-material/Reddit';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TwitterIcon from '@mui/icons-material/Twitter';


export interface SocialButtonsPanelProps {
  spacing?: "normal"|"tight"|"loose",
  className ?:string,
}
export function SocialButtonsPanel(props:SocialButtonsPanelProps)
{
  const { className ="", spacing="normal" } = props;
  return(<div className={`mx-2 flex flex-row justify-center items-center ${className}`}>
    <SocialIconButton variant="twitter" spacing={spacing}/>
    <SocialIconButton variant="linkedin" spacing={spacing}/>
    <SocialIconButton variant="reddit" spacing={spacing}/>
    <SocialIconButton variant="telegram" spacing={spacing}/>

  </div>);
}

export interface SocialIconButtonProps {
  variant: "reddit"|"linkedin"|"twitter"| "telegram",
  spacing?: "normal"|"tight"|"loose"
}
export function SocialIconButton(props:SocialIconButtonProps)
{
  const { variant="linkedin", spacing="normal" } = props;
  let SocialIcon = LinkedInIcon;
  let href="https://www.linkedin.com/company/notorosdlt";
  switch(variant)
  {
    case "linkedin":
    {
      SocialIcon=LinkedInIcon;
      href="https://www.linkedin.com/company/notorosdlt";
      break;
    }
    case "twitter":
    {
      SocialIcon=TwitterIcon;
      href="https://twitter.com/notorosdlt";
      break;
    }
    case "reddit":
    {
      SocialIcon=RedditIcon;
      href="https://www.reddit.com/r/Notoros";
      break;
    }
    case "telegram":
    {
      SocialIcon=TelegramIcon;
      href="http://t.me/NotorosOfficial";
      break;
    }
  }
  let className ="text-secondary ";
  switch(spacing)
  {
    case "normal":{
      className += "mx-4";
      break;
    }
    case "tight":{
      className += "mx-2";
      break;
    }
    case "loose":{
      className += "mx-8";
      break;
    }
  }
  return(<IconButton size="small" onClick={()=>{
    window.open(href, "_blank");
    return;
  }}>
    <SocialIcon className={className}/>
  </IconButton>);
}