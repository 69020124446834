import React from 'react';

export function CaseStudyContentSection(props:any)
{
  const { content=[], caseStudyLogo}= props;
  return(<div className={"md:px-12 xl:px-16 max-w-5xl"}>
        <img src={caseStudyLogo} className="inline-block max-h-28 md:max-h-36 my-4" />

    <h4 className="text-light text-left text-2xl font-semibold">Case Study</h4>
    <hr style={{borderWidth: '1px', width: '100%', borderColor: '#eee', marginTop: '1rem', marginBottom: '2rem', borderStyle:"solid"}}/>
    {content.map((c:any, index:number)=><p key={index} className={"text-light text-sm md:text-base text-left my-1 mb-4"}>{c}</p>)}
  </div>)
}

export function CaseStudyHeroBanner(props:any)
{
  const { image="", title="", subtitle="", bannerImage="" }=props;

  return(<div
    style={{backgroundImage: `url(${bannerImage})`, backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'}}
    className='w-full bg-no-repeat h-48 flex flex-col justify-center content-center'
  >
    <TitleCutout title={title} subtitle={subtitle}/>
    {/*  */}
  </div>);
}

export function TitleCutout(props:any)
{
  const { title="", subtitle } = props;

  return(<div className={"flex flex-row items-center w-4/5 h-16 "}>
    <div className='bg-black bg-opacity-70 h-full grow basis-1/5'></div>
    <span className="bg-black bg-opacity-70 h-full basis-4/5 flex flex-col justify-center md:px-12 px-4">
      <h1 className={"md:text-3xl text-xl whitespace-nowrap font-bold text-light px-2 md:px-8"}>{title} : {subtitle}</h1>
    </span>
    <span className={"md:w-24 w-12 h-full bg-black bg-opacity-70"} style={{clipPath:"polygon(0% 0%, 100% 0%, -1% 101%, -1% -1%)"}}></span>
  </div>)
}

export function CaseStudyDetailsPage(props:any)
{
  const {
    caseStudyDetails = {
      title: "",
      subtitle: "",
      image: "",
      bannerImage: "",
      content:[],

    }
  } = props;
  return (<React.Fragment>
    <CaseStudyHeroBanner {...caseStudyDetails}/>
    <div className="bg-darkShadow flex flex-col content-center items-center justify-between py-2 md:py-8 px-4 md:px-16">
      <CaseStudyContentSection {...caseStudyDetails} caseStudyLogo={caseStudyDetails.image}/>
    </div>
  </React.Fragment>);
}