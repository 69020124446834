import React from "react";
import Section from "../layout/section/Section";
import { SectionTitle } from "../layout/section/SectionTitle";
import { CaseStudyThumbnail, CaseStudyData } from "../cards/CaseStudyThumbnail";
import SunifiedJson from '../../appData/caseStudies/Sunified.json';
import AllosenseJson from '../../appData/caseStudies/Allosense.json';
import NGAJson from '../../appData/caseStudies/NGA.json';
import IRXReminderJson from '../../appData/caseStudies/IRXReminder.json'

const caseStudyData : Array<CaseStudyData>= [
  SunifiedJson,
  AllosenseJson,
  IRXReminderJson,
  NGAJson,
];

export function CaseStudiesSection (props:any) {
  return (<Section className="my-2 md:my-8 bg-darkShadow flex flex-col justify-around content-center items-center">
    <SectionTitle title={"Case Studies"}/>
    {
      caseStudyData.map((item, key:number)=>{
        return(<CaseStudyThumbnail key={key} item={item}/>)
      })
    }
  </Section>);
};

export default CaseStudiesSection;
