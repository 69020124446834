import React from "react";
import featuresData from '../../appData/featuresData'
import { SectionTitle } from "../layout/section/SectionTitle";

export function FeaturesSection(props:any){
  return (<div className="bg-dark flex flex-col items-center py-4 md:px-8 pb-8">
    <SectionTitle title={"Features"}/>
    <div className={"flex flex-row justify-evenly content-start flex-wrap text-light pb-2"}>
      {featuresData.map((item, key:number)=>{
        return(<FeatureItem {...item} key={key}/>);
      })}
    </div>
  </div>);
}

export function FeatureItem(props:any)
{
  const { image, title, body } = props;
  return(<div className={"m-2 md:m-8 flex flex-col items-center text-center text-light pb-2 w-56 border border-transparent py-2"}>
    <span className="text-light my-0 mb-4 text-center">
      <img src={image} alt={title} className={` w-12 max-h-12`} style={{filter: `invert(100%)`}} />
    </span>
    <h5 className="text-secondary text-xl">
      {title}
    </h5>
    <p className="text-base text-light">{body}</p>
  </div>)
}


export default FeaturesSection;
