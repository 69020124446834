import { useState } from "react";
import { MenuIcon } from "../util/Icons";
import { AppNavigationLinks } from "../menu/NavigationLinks";
import { Logo } from "./Logo";
import { Drawer } from "./Drawer";
import { IconButton } from "../util/Buttons";
import { SocialButtonsPanel } from "./Socials";
import { Link } from "react-router-dom";

export function Header(props:any)
{
  const { navOpen=false, links=[] } = props;
  const [ navIsOpen, setNavOpen ] = useState(navOpen);
  function handleNavButtonClick(o:boolean=!navIsOpen)
  {
    setNavOpen(o);
    return(o);
  }
  return(<div className="w-full flex flex-row justify-between bg-darkShadow">
    <span className="inline-flex flex-row justify-between py-2">
      <Link to="/" className="px-6 py-2">
        <Logo/>
      </Link>
    </span>
    <span className={"px-4 py-2 inline-flex flex-row md:hidden"}>
      <IconButton onClick={handleNavButtonClick}>
        <MenuIcon className="text-slate-300 hover:text-slate-100"/>
      </IconButton>
    </span>
    <span className="py-2 px-2 pr-4 hidden md:flex flex-row items-center justify-around bg-inherit">
      <AppNavigationLinks links={links} vertical={false} />
      <SocialButtonsPanel spacing="tight"/>
    </span>
    <AppNavigationDrawer open={navIsOpen} onControlClick={handleNavButtonClick} links={links} />
  </div>)
}

export function AppNavigationDrawer(props:any)
{
  const { open=false, onControlClick =(b:boolean)=>true, links=[] } = props;
  return(<Drawer className="bg-darkShadow py-2 px-2" open={open} setOpen={onControlClick} >
  <div className="flex flex-row justify-start px-2 py-2 mb-4">
    <IconButton onClick={onControlClick}>
      <MenuIcon className="text-slate-300 hover:text-slate-100"/>
    </IconButton>
  </div>
  <AppNavigationLinks links={links} vertical={true} />
  <span className="grow"/>
  <div className="text-center py-4">
    <SocialButtonsPanel spacing="tight"/>
  </div>
</Drawer>)
}

export default Header;
