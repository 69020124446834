import React from "react";
import { ImageCards } from "../cards/ImageCard";
import Section from "../layout/section/Section";
import { SectionTitle } from "../layout/section/SectionTitle";

export function UseCasesSection (props:any) {
  return (<Section className="my-2 md:my-8">
    <SectionTitle title={"Use Cases"}/>
    <ImageCards/>
  </Section>);
};

export default UseCasesSection;
