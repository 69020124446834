import React from 'react'
import { LockOutlinedIcon } from '../util/Icons';
import { Link } from 'react-router-dom';

interface ItemThumbnailProps {
  item: any,
}

export function ItemsThumbnailContent(props:any)
{
  const { image="", title="" } = props;
  return(<div className='w-full h-full flex flex-row flex-nowrap justify-start'>
    <div className="basis-1/2 shrink bg-cover" style={{backgroundImage: `url(${image})`}}></div>
    <div className='h-full basis-1/2 bg-darkShadow text-light py-2 px-4 md:py-4 md:pl-8 shrink-0'>
      <h5 className='font-semibold text-sm md:text-base text-left underline-offset-2 group-hover:underline'>{title}</h5>
    </div>
  </div>)
}

export function ItemsThumbnailUnreleased(props:any)
{
  return(<div className='bg-gray-500 h-full flex justify-center items-center text-light flex-row'>
    <LockOutlinedIcon style={{color: 'white'}} />
    <h5 className='inline text-sm m-4 text-light'>COMING SOON</h5>
  </div>);
}

export function ItemThumbnail(props: ItemThumbnailProps) {
  const { item } = props
  const content = (!!item.disabled)?<ItemsThumbnailUnreleased />:<ItemsThumbnailContent title={item.title} image={item.image}/>
  return (<Link className={"inline-block h-24 md:h-32 w-64 sm:w-80 md:w-96 group shadow-lg shadow-black m-1 md:m-4 transition-transform hover:shadow hover:scale-[1.01]"} key={item.title} to={item.disabled?"":item.route} >
    {content}
  </Link>);
}

export default ItemThumbnail
