import React from "react";
import "./leadForm.css";

interface WebContacthtmlFormProps {
  onSubmit: () => boolean;
}

function validateEmail() {
  const form = (document.forms as any)['WebToLeads5180905000001695001'];
  var emailFld = form.querySelectorAll("[ftype=email]");
  var i;
  for (i = 0; i < emailFld.length; i++) {
    var emailVal = emailFld[i].value;
    if (emailVal.replace(/^\s+|\s+$/g, "").length != 0) {
      var atpos = emailVal.indexOf("@");
      var dotpos = emailVal.lastIndexOf(".");
      if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
        alert("Please enter a valid email address. ");
        emailFld[i].focus();
        return false;
      }
    }
  }
  return true;
}

function checkMandatory() {
  const mndFileds = ['Last Name', 'Email', 'Description'];
  const fldLangVal = ['Last\x20Name', 'Email', 'Message'];
  for (let i = 0; i < mndFileds.length; i++) {
    const fieldObj = (document.forms as any)['WebToLeads5180905000001695001'][mndFileds[i]];
    if (fieldObj) {
      if (fieldObj.value.trim().length == 0) {
        if (fieldObj.type == 'file') {
          alert('Please select a file to upload.');
          fieldObj.focus();
          return false;
        }
        alert(fldLangVal[i] + ' cannot be empty.');
        fieldObj.focus();
        return false;
      } else if (fieldObj.nodeName == 'SELECT') {
        if (fieldObj.options[fieldObj.selectedIndex].value == '-None-') {
          alert(fldLangVal[i] + ' cannot be none.');
          fieldObj.focus();
          return false;
        }
      } else if (fieldObj.type == 'checkbox') {
        if (fieldObj.checked == false) {
          alert('Please accept  ' + fldLangVal[i]);
          fieldObj.focus();
          return false;
        }
      }
      try {
        if (fieldObj.name == 'Last Name') {
          const name = fieldObj.value;
        }
      } catch (e) {}
    }
  }
  if (!validateEmail()) {
    return false;
  }
  document.querySelector('.crmWebToEntityForm .formsubmit')!.setAttribute('disabled', 'true');
}

function tooltipShow(el: HTMLElement) {
  const tooltip = el.nextElementSibling as HTMLElement;
  const tooltipDisplay = tooltip.style.display;
  if (tooltipDisplay == 'none') {
    const allTooltip = document.getElementsByClassName('zcwf_tooltip_over');
    for (let i = 0; i < allTooltip.length; i++) {
      (allTooltip[i] as HTMLElement).style.display = 'none';
    }
    tooltip.style.display = 'block';
  } else {
    tooltip.style.display = 'none';
  }
}


function WebContactForm(props: WebContacthtmlFormProps) {
  const { onSubmit = () => true } = props;

  return (<div id='crmWebToEntityForm' className='zcwf_lblLeft crmWebToEntityForm bg-inherit text-secondary' style={{maxWidth: '600px'}}>
   <form action='https://crm.zoho.com/crm/WebToLeadForm' name={'WebToLeads5180905000001695001'} method='POST' onSubmit={() => checkMandatory()}>
 <input type='text' style={{display: 'none'}} name='xnQsjsdp' value='fcc064e62621ccb52bdb6049dfd697fdcd543f0cd11bc3e65003c960441fe458'/>
 <input type='hidden' name='zc_gad' id='zc_gad' value=''/>
 <input type='text' style={{display: 'none'}} name='xmIwtLD' value='13d18d44710e7ba48b9ab18012bdf332eba7bd9030b398c13799162ba3598c58'/>
 <input type='text'  style={{display: 'none'}} name='actionType' value='TGVhZHM='/>
 <input type='text' style={{display: 'none'}} name='returnURL' value='https&#x3a;&#x2f;&#x2f;notoros.io'/> 
 <div className='zcwf_title' style={{maxWidth: '600px'}}></div>
<div className='zcwf_row'><div className='zcwf_col_lab' ><label htmlFor='First_Name'>First Name</label></div><div className='zcwf_col_fld'><input type='text' id='First_Name' name='First Name' maxLength={40}></input><div className='zcwf_col_help'></div></div></div>
<div className='zcwf_row'><div className='zcwf_col_lab'><label htmlFor='Last_Name'>Last Name<span>*</span></label></div><div className='zcwf_col_fld'><input type='text' id='Last_Name' name='Last Name' maxLength={80}></input><div className='zcwf_col_help'></div></div></div>
<div className='zcwf_row'><div className='zcwf_col_lab'><label htmlFor='Email'>Email<span>*</span></label></div><div className='zcwf_col_fld'><input type='text' id='Email' name='Email' maxLength={100}></input><div className='zcwf_col_help'></div></div></div>
<div className='zcwf_row'><div className='zcwf_col_lab'><label htmlFor='Company'>Company</label></div><div className='zcwf_col_fld'><input type='text' id='Company' name='Company' maxLength={200}></input><div className='zcwf_col_help'></div></div></div>
<div className='zcwf_row'><div className='zcwf_col_lab'><label htmlFor='Description'>Message<span>*</span></label></div><div className='zcwf_col_fld'><textarea id='Description' name='Description'></textarea><div className='zcwf_col_help'></div></div></div><div className='zcwf_row'><div className='zcwf_col_lab'></div><div className='zcwf_col_fld'><input type='submit' id='formsubmit' className='formsubmit zcwf_button' value='Submit' title='Submit'/><input type='reset' className='zcwf_button' name='reset' value='Reset' title='Reset'/></div></div>
</form>
</div>);
}
export default WebContactForm;
