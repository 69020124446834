import React, { useEffect, useRef } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { useLocation } from "react-router-dom";

// functional component
export function Layout(props:any){
  const { headerLinks=[], toggleTheme, useDefaultTheme, children, ...otherProps } = props;
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();
  useEffect(() => {
    if(contentRef.current)
    {
      contentRef.current.scrollTo(0, 0);
    }
  }, [pathname]);
  return (
    <div className={"w-full min-h-screen h-screen flex flex-col text-center bg-dark "}>
      <Header links={headerLinks} />
      <div ref={contentRef} className="grow overflow-y-auto flex flex-col justify-between">
        <main >
          {children}
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </div>
  );
};

export default Layout;
