import React, { useEffect, useRef } from "react";
import { ChevronDownIcon } from "../util/Icons";
import { ChevronUpIcon } from "../util/Icons";
import { Link } from "react-router-dom";
import { setUpDocumentListener } from "../../utils/documentClickListener";

export interface DropdownMenuProps {
  id: string | number,
  title: string | React.ReactElement | React.ReactElement[],
  links: Array<{id:string | number, text:string| React.ReactElement | React.ReactElement[], link:string, subheading:string}>,
  open ?: boolean,
  setOpen ?: (o:boolean)=>any,
  className ?: string,
}

export function DropdownMenu(props:DropdownMenuProps)
{
  const { links=[], title="", open=false, setOpen=(p:boolean)=>true, className="" } = props;
  const ref = useRef<null | HTMLDivElement> (null);
  const ArrowIcon=open?ChevronUpIcon:ChevronDownIcon;

  // Adds event listener for clicks outside of menu and
  //   closes menu if open
  useEffect(()=>{
    return(setUpDocumentListener(ref, ()=>setOpen(false)));
  },[]);

  return(<div ref={ref} className={"bg-inherit relative "+className}>
    <div className="bg-inherit">
      <button className="w-full px-2 rounded-sm hover:cursor-pointer hover:bg-white hover:bg-opacity-10 inline-flex flex-row flex-nowrap justify-between items-center text-base text-secondary" onClick={(e:any)=>{
        e.stopPropagation();
        e.preventDefault();
        setOpen(!open);
      }}>
        <h3 className={"inline-block  "+(open?"underline":"")}>{title}</h3>
        <ArrowIcon className="w-4 h-4 mx-1" strokeWidth="3"/>
      </button>
    </div>
    <div className={`bg-inherit bg-opacity-100 shadow-lg rounded-b-sm text-left text-secondary absolute ${open ? '' : 'hidden'} w-auto`}>
      <ul className="bg-inherit rounded-sm">
        {links.map(({text, link, subheading}, i:number)=>{
          const id = i.toString(); // Convert index to string to use as ID
          return(<li key={id} className={"bg-inherit rounded-sm"} >
            <Link to={link} className="w-full px-2 py-1 rounded-sm inline-block hover:bg-white hover:bg-opacity-10" onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}>
              <div className="p-1">
                <div>{text}</div>
              </div>
              <div className="flex flex-row">
            
              </div>
              {subheading && <div className="flex flex-row whitespace-nowrap justify-between">
                <span className="text-xs mr-2.5 p-1 m-w-10">{subheading}</span>
                <span className="mt-1 w-3.5 h-3.5 rounded-full bg-green-400 flex items-center justify-center text-black text-xs">&rarr;</span>
              </div>}
            </Link>
          </li>)
        })}
      </ul>
    </div>
  </div>);
}