import React from "react";
import { Link } from "react-router-dom";
import { homepageUseCaseIndustryData, UseCaseData } from "../../appData/useCaseData";

export function ImageCards(props:any)
{
  return (<div className="w-full flex justify-center"><div className="flex flex-row justify-center flex-wrap space-4 max-w-6xl">
      {
      homepageUseCaseIndustryData.map((data, key:number)=> {
        return(<ImageCard key={key} {...data} />);
      })
    } 
  </div></div>);
}

export function ImageCard(props:any)
{
  const imagesSourceDir = "/images";
  const { title="", image="", entries=[], link="" } = props;
  const imageSrc = imagesSourceDir + image;
  return(
    <Link to={link}>
      <span className="m-2 inline-block text-light bg-darkShadow group hover:cursor-pointer w-64 rounded-md hover:scale-[101%] h-96">
        <div className="flex justify-center h-3/5">{/*Image*/}
          <img src={imageSrc} alt={title} className="w-full h-full rounded-t-md grayscale group-hover:grayscale-0" />
        </div>
        <div className="px-4 py-4 h-2/5">
          <h1 className="text-2xl group-hover:text-secondary no-underline">
            {title}
          </h1>
          <div className="h-full">
            <ul className="py-2" style={{ height: "84px" /* or any other height that works for your use case */ }}>
              {entries.map((entry:UseCaseData, key:number)=>{
                return(
                  <li key={key}>
                    <Link to={entry.route ||""} className="text-sm py-2 no-underline hover:underline hover:text-secondary">
                      {entry.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </span>
    </Link>
  );
}


export default ImageCards;