import React from "react";

// Pages
import Home from "./views/Home/Home";
import Dashboard from "./views/Developer/Developer";
import Faq from "./views/FAQ/FrequentQuestionsPage";
import About from "./views/About/About";
import Contact from "./views/Contact/Contact";
import { UseCaseDetailsPage } from "./views/UseCases/UseCaseDetailsPage";
import { CaseStudyDetailsPage } from "./views/CaseStudies/CaseStudyDetailsPage";

// Case Studies
import AllosenseJson from './appData/caseStudies/Allosense.json';
import NGAJson from './appData/caseStudies/NGA.json';
import SunifiedJson from './appData/caseStudies/Sunified.json';
import IRXReminderJson from './appData/caseStudies/IRXReminder.json';


//Use Cases
import UseCasesHome from "./views/UseCases/UseCasesHome";
import CuttingPaperworkJson from './appData/useCases/CuttingPaperwork.json';
import LoyaltyRewardsProgramJson from './appData/useCases/LoyaltyRewardsProgram.json';
import ProofOfEthicalSourcingJson from './appData/useCases/ProofOfEthicalSourcing.json';
import ReducingRecallCostsJson from './appData/useCases/ReducingTheCostOfRecalls.json';
import EmergencyNotificationSystemJson from './appData/useCases/EmergencyNotifications.json';
import CostEffectiveTicketSalesJson from './appData/useCases/CostEffectiveTicketSales.json';
import TamperResistantAuditLogsJson from './appData/useCases/TamperResistantAuditLogs.json';
import CooperativeEcoConservationJson from './appData/useCases/CooperativeEcoConservation.json';
import PreventingKnockOffProductsJson from './appData/useCases/PreventingKnockOffProducts.json';
import InstantMicroInsuranceJson from './appData/useCases/InstantMicroInsurance.json';
import SensingAsAServiceJson from './appData/useCases/SensingAsAService.json';
import PeertoPeerEnergyTradingJson from './appData/useCases/PeerToPeerEnergyTrading.json';
import AutomatedBookkeepingJson from './appData/useCases/AutomatedBookkeeping.json';
import MPConMedicalDataJson from './appData/useCases/MPCOnMedicalData.json';
import EnhancingPharmaceuticalPrecisionJson from './appData/useCases/EnhancingPharmaceuticalPrecision.json';
import WaterProvenanceAndQualityJson from './appData/useCases/WaterProvenanceAndQuality.json';
import DataSovereigntyJson from './appData/useCases/DataSovereignty.json';
import MaintenanceRecordsJson from './appData/useCases/MaintenanceRecords.json';


// RouteItem is an interface for defining the application routes and navigation menu items
export interface RouteItem {
  title: String,
  subheading?: string,
  tooltip?: String
  path?: String
  component?: (props?:any)=>React.ReactElement,
  hidden?: boolean
  subRoutes?: Array<RouteItem>
}

export const UseCaseRoute = (jsonData:any)=>(props:any)=> {
  return (UseCaseDetailsPage({
    ...props,
    useCaseDetails: jsonData
  }));
}

export const CaseStudyRoute = (jsonData:any)=>(props:any)=> {
  return (CaseStudyDetailsPage({
    ...props,
    caseStudyDetails: jsonData
  }));
}

// define app routes
export const routes: Array<RouteItem> = [
  {
    title: "Home",
    tooltip: "Home",
    path: "/",
    hidden: true,
    component: Home,
  },
  {
    title: "Developers",
    hidden: true,
    tooltip: "Developers",
    path: "/developers",
    component: Dashboard,
  },
  // {
  //   title: "FAQ",
  //   hidden: false,
  //   tooltip: "Frequently Asked Questions",
  //   path: "/faq",
  //   component: Faq,
  // },
  {
    title: "Use Cases",
    tooltip: "Use Cases",
    path: "/use-cases/index",
    hidden: false,
    subRoutes: [
      {
        title: "All Use Cases",
        subheading: "View all use cases",
        tooltip: "Use Cases",
        path: "/use-cases/index",
        hidden: false,
        component: UseCasesHome,
      },
      {
        title: "Security",
        subheading: "Zero Trust Security Applications",
        tooltip: "Security",
        path: "/use-cases/index?selection=security",
        hidden: false,
        component: UseCasesHome,
      },
      {
        title: "Supply Chain",
        subheading: "Zero Trust Supply Chain Applications",
        tooltip: "Supply Chain",
        path: "/use-cases/index?selection=supply_chain",
        hidden: false,
        component: UseCasesHome,
      },
      {
        title: "Environment",
        subheading: "Zero Trust Environmental Applications",
        tooltip: "Environment",
        path: "/use-cases/index?selection=environment",
        hidden: false,
        component: UseCasesHome,
      },
      {
        title: "Productivity",
        subheading: "Zero Trust Productivity Applications",
        tooltip: "Productivity",
        path: "/use-cases/index?selection=productivity",
        hidden: false,
        component: UseCasesHome,
      },
      {
        title: "Finance",
        subheading: "Zero Trust Finance Applications",
        tooltip: "Finance",
        path: "/use-cases/index?selection=finance",
        hidden: false,
        component: UseCasesHome,
      },
      {
        title: "Healthcare",
        subheading: "Zero Trust Health Care Applications",
        tooltip: "Healthcare",
        path: "/use-cases/index?selection=healthcare",
        hidden: false,
        component: UseCasesHome,
      },
      // {
      //   title: "Insurance",
      //   tooltip: "Insurance",
      //   path: "/use-cases/index?selection=insurance",
      //   hidden: false,
      //   component: UseCasesHome,
      // },
      {
        title: "Cutting Paperwork",
        tooltip: "Cutting Paperwork",
        path: "/use-cases/cutting-paperwork",
        hidden: true,
        component: UseCaseRoute(CuttingPaperworkJson),
      },
      {
        title: "Loyalty Rewards Programs",
        tooltip: "Loyalty Rewards Programs",
        path: "/use-cases/loyalty-rewards",
        hidden: true,
        component: UseCaseRoute(LoyaltyRewardsProgramJson),
      },
      {
        title: "Proof of Ethical Sourcing",
        tooltip: "Ethical Sourcing",
        path: "/use-cases/ethical-sourcing",
        hidden: true,
        component: UseCaseRoute(ProofOfEthicalSourcingJson),
      },
      {
        title: "Reducing Costs of Recalls",
        tooltip: "Reducing Recall Costs",
        path: "/use-cases/reducing-recall-costs",
        hidden: true,
        component: UseCaseRoute(ReducingRecallCostsJson),
      },
      {
        title: "Emergency Notification Systems",
        tooltip: "Emergency Notificaiton Systems",
        path: "/use-cases/emergency-notification-systems",
        hidden: true,
        component: UseCaseRoute(EmergencyNotificationSystemJson),
      },
      // {
      //   title: "Cost Effective Ticket Sales",
      //   tooltip: "Cost Effective Ticket Sales",
      //   path: "/use-cases/cost-effective-ticket-sales",
      //   hidden: true,
      //   component: UseCaseRoute(CostEffectiveTicketSalesJson),
      // },
      {
        title: "Tamper Resistant Audit Logs",
        tooltip: "Tamper Resistant Audit Logs",
        path: "/use-cases/tamper-resistant-audit-logs",
        hidden: true,
        component: UseCaseRoute(TamperResistantAuditLogsJson),
      },
      {
        title: "Cooperatvive Ecological Conservation",
        tooltip: "Cooperatvive Ecological Conservation",
        path: "/use-cases/cooperative-ecological-conservation",
        hidden: true,
        component: UseCaseRoute(CooperativeEcoConservationJson),
      },
      {
        title: "Sensing as a Service",
        tooltip: "Sensing as a Service",
        path: "/use-cases/sensing-as-a-service",
        hidden: true,
        component: UseCaseRoute(SensingAsAServiceJson),
      },
      {
        title: "Preventing Knock-Off Products",
        tooltip: "Preventing Knock-Off Products",
        path: "/use-cases/preventing-knock-off-products",
        hidden: true,
        component: UseCaseRoute(PreventingKnockOffProductsJson),
      },
      {
        title: "Instant Micro Insurance",
        tooltip: "Instant Micro Insurance",
        path: "/use-cases/instant-micro-insurance",
        hidden: true,
        component: UseCaseRoute(InstantMicroInsuranceJson),
      },
      {
        title: "Peer-to-Peer Energy Trading",
        tooltip: "Peer-to-Peer Energy Trading",
        path: "/use-cases/peer-to-peer-energy-trading",
        hidden: true,
        component: UseCaseRoute(PeertoPeerEnergyTradingJson),
      },

      {
        title: "Automated Bookkeeping",
        tooltip: "Automated Bookkeeeping",
        path: "/use-cases/automated-bookkeeping",
        hidden: true,
        component: UseCaseRoute(AutomatedBookkeepingJson),
      },

      {
        title: "Multi-Pary Compute on Medical Data",
        tooltip: "Multi-Pary Compute on Medical Data",
        path: "/use-cases/mpc-on-medical-data",
        hidden: true,
        component: UseCaseRoute(MPConMedicalDataJson),
      },
      {
        title: "Enhancing pharmaceutical Precision",
        tooltip: "Enhancing pharmaceutical Precision",
        path: "/use-cases/enhancing-pharmaceutical-precision",
        hidden: true,
        component: UseCaseRoute(EnhancingPharmaceuticalPrecisionJson),
      },
      // {
      //   title: "Data Sovereignty",
      //   tooltip: "Data Sovereignty",
      //   path: "/use-cases/data-sovereignty",
      //   hidden: true,
      //   component: UseCaseRoute(DataSovereigntyJson),
      // },
      //  {
      //   title: "Immutable Maintenance Records",
      //   tooltip: "Immutable Maintenance Records",
      //   path: "/use-cases/immutable-maintenance-records",
      //   hidden: true,
      //   component: UseCaseRoute(MaintenanceRecordsJson),
      // },
      // {
      //   title: "Water Provenance and Quality",
      //   tooltip: "Water Provenance and Quality",
      //   path: "/use-cases/water-provenance-and-quality",
      //   hidden: true,
      //   component: UseCaseRoute(WaterProvenaceAndQualityJson),
      // },
    ],
  },
  {
    title: "Case Studies",
    tooltip: "Case Studies",
    // path: "/case-studies/",
    hidden: false,
    subRoutes: [
      {
        title: "Allosense",
        subheading: "Cyber-Physical Asset Tracking",
        tooltip: "Allosense",
        path: "/case-studies/study/allosense",
        hidden: false,
        component: CaseStudyRoute(AllosenseJson),
      },
      {
        title: "NGA",
        subheading: "Secure Content Distribution",
        tooltip: "NGA",
        path: "/case-studies/study/nga",
        hidden: false,
        component: CaseStudyRoute(NGAJson),
      },
      {
        title: "Sunified",
        subheading: "IoT Data Tracing",
        tooltip: "Sunified",
        path: "/case-studies/study/sunified",
        hidden: false,
        component: CaseStudyRoute(SunifiedJson),
      },
      {
        title: "IRX Reminder",
        subheading: "Private Event Records",
        tooltip: "IRX Reminder",
        path: "/case-studies/study/irxReminder",
        hidden: false,
        component: CaseStudyRoute(IRXReminderJson),
      },
    ]
  },
  // {
  //   title: "About",
  //   tooltip: "About Notoros",
  //   hidden: false,
  //   path: "/about",
  //   component: About,
  // },
  {
    title: "Docs",
    tooltip: "Notoros Documentation",
    hidden: false,
    path: "/docs",
    // component: About,
  },
  {
    title: "Contact",
    tooltip: "Contact Us",
    hidden: false,
    path: "/contact",
    component: Contact,
  }
];
