import CuttingPaperworkJson from '../appData/useCases/CuttingPaperwork.json';
import LoyaltyRewardsProgramJson from '../appData/useCases/LoyaltyRewardsProgram.json';
import ProofOfEthicalSourcingJson from '../appData/useCases/ProofOfEthicalSourcing.json';
import ReducingRecallCostsJson from '../appData/useCases/ReducingTheCostOfRecalls.json';
import EmergencyNotificationSystemJson from '../appData/useCases/EmergencyNotifications.json';
import CostEffectiveTicketSalesJson from '../appData/useCases/CostEffectiveTicketSales.json';
import TamperResistantAuditLogsJson from '../appData/useCases/TamperResistantAuditLogs.json';
import CooperativeEcoConservationJson from '../appData/useCases/CooperativeEcoConservation.json';
import PreventingKnockOffProductsJson from '../appData/useCases/PreventingKnockOffProducts.json';
import InstantMicroInsuranceJson from '../appData/useCases/InstantMicroInsurance.json';
import SensingAsAServiceJson from '../appData/useCases/SensingAsAService.json';
import PeertoPeerEnergyTradingJson from '../appData/useCases/PeerToPeerEnergyTrading.json';
import AutomatedBookkeepingJson from '../appData/useCases/AutomatedBookkeeping.json';
import MPConMedicalDataJson from '../appData/useCases/MPCOnMedicalData.json';
import EnhancingPharmaceuticalPrecisionJson from '../appData/useCases/EnhancingPharmaceuticalPrecision.json';
import WaterProvenanceAndQualityJson from '../appData/useCases/WaterProvenanceAndQuality.json';
import DataSovereigntyJson from '../appData/useCases/DataSovereignty.json';
import MaintenanceRecordsJson from '../appData/useCases/MaintenanceRecords.json';

export interface UseCaseData {
  title:string,
  industry:string,
  image: string,
  route: string
  problem: Array<string>,
  solution: Array<string>,
  citations: Array<{link:string, text:string}>,
  links: Array<{link:string, text:string}>,
};

export const useCaseData: Array<UseCaseData> = [
  CooperativeEcoConservationJson,
  CuttingPaperworkJson,
  SensingAsAServiceJson,
  LoyaltyRewardsProgramJson,
  AutomatedBookkeepingJson,
  PreventingKnockOffProductsJson,
  ProofOfEthicalSourcingJson,
  ReducingRecallCostsJson,
  TamperResistantAuditLogsJson,
  EmergencyNotificationSystemJson,
  PeertoPeerEnergyTradingJson,
  InstantMicroInsuranceJson,
  MPConMedicalDataJson,
  EnhancingPharmaceuticalPrecisionJson,
  // CostEffectiveTicketSalesJson,
  // WaterProvenanceAndQualityJson,
  // DataSovereigntyJson,
  // MaintenanceRecordsJson,
];

export const sortedUseCases = useCaseData.sort((a, b)=>{
  if(a.industry == b.industry)
  {
    return(0)
  }
  else
  {
    return((a.industry > b.industry)?1:-1);
  }
});

export const useCasesByIndustry = useCaseData.reduce((prev:{[key:string]:Array<UseCaseData>}, current, i:number)=>{
  const { industry } = current;
  const _industry = industry.replace(" ","_").toLowerCase();
  if(!Array.isArray(prev[_industry]))
  {
    prev[_industry] = [];
  }
  prev[_industry].push(current);
  return(prev);
},{});

export interface UseCaseIndustryCardData {
  title: string,
  link: string,
  image: string,
  entries: Array<UseCaseData>
}

export const homepageUseCaseIndustryData: Array<UseCaseIndustryCardData> = [
  {
    title: "Security",
    link: "/use-cases/index?selection=security",
    image: "/useCaseImages/securityBlue.jpeg",
    entries: useCasesByIndustry["security"]
  },
  {
    title: "Supply Chain",
    link: "/use-cases/index?selection=supply_chain",
    image: "/useCaseImages/sourcing.jpeg",
    entries: useCasesByIndustry["supply_chain"]
  },
  {
    title: "Environment",
    link: "/use-cases/index?selection=environment",
    image: "/useCaseImages/environment.jpeg",
    entries: useCasesByIndustry["environment"]
  },
  {
    title: "Productivity",
    link: "/use-cases/index?selection=productivity",
    image: "/useCaseImages/productivity.jpeg",
    entries: useCasesByIndustry["productivity"],
  },
  {
    title: "Finance",
    link: "/use-cases/index?selection=finance",
    image: "/useCaseImages/finance.jpeg",
    entries: useCasesByIndustry["finance"],
  },
  {
    title: "Healthcare",
    link: "/use-cases/index?selection=healthcare",
    image: "/useCaseImages/healthcare.jpeg",
    entries: useCasesByIndustry["healthcare"]
  },
  // {
  //   title: "Insurance",
  //   link: "/use-cases/index?selection=insurance",
  //   image: "/useCaseImages/insurance.jpeg",
  //   entries: useCasesByIndustry["insurance"],
  // },
];
