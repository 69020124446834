import React, { useState, } from 'react';
import { ActionButton } from '../util/Buttons';
import { emailRegex } from '../../utils/util';
import { InputText } from '../util/InputText';
import { Modal } from './Modal';
import NewsLetterSignupForm from '../../views/Contact/Contact_Forms/NewsLetterSignupForm';

export function NewsletterSignupModal(props:any)
{
  const { open, onClose, onSubmit=async(v:string)=>"" } = props;
  const [ submitted, setSubmitted ] = useState<string>("");
  const [ submissionError, setSubmissionError ] = useState<string>("");

  function handleClose()
  {
    setSubmitted("");
    setSubmissionError("");
    onClose();
  }
  function submissionHandler(email:string)
  {
    onSubmit(email).then((err:string="")=>{
      if(err.length == 0)
      {
        setSubmitted(email);
      }
      else
      {
        setSubmissionError(err);
      }
    });
    // TODO: Integrate Signup Function
    return(true);
  }
  return(<Modal open={open} onClose={handleClose} >
    {
      submissionError !== ""?<NewsletterSignupError error={submissionError} />:
        (submitted !==""?<NewsletterSignupSuccess/>:<NewsletterSignupContent onSubmit={submissionHandler}/>)
    }
  </Modal>)
}

export function NewsletterSignupContent(props:any)
{
  const { onSubmit=()=>true } = props;
  const [emailValid, setEmailValid] = useState<boolean>(true);
  const [userEmail, setUserEmail] = useState<string>("");
  function handleChange(v: string)
  {
    setUserEmail(v);
    setEmailValid(true);
  }
  function handleSubmit()
  {
    if(emailRegex.test(userEmail))
    {
      return(onSubmit(userEmail));
    }
    else
    {
      setEmailValid(false);
      return;
    }
  }
  return(<div className="inline-flex px-2 md:px-8 py-2 md:py-4 my-2 bg-darkShadow border-dark max-w-3xl text-center flex-col items-center ">
    <p className="my-1 text-base font-semibold text-center text-light">
     Sign up for the Notoros Newsletter    </p>
    <p className="my-1 text-base text-center text-light px-8">
      Get the latest updates and early access to Notoros products.
    </p>
    <div className='w-full flex justify-center'>
      <NewsLetterSignupForm />
    </div>
    {emailValid?null:<p className='py-1 text-sm font-semibold text-center text-red-700'>Please Enter a Valid Email</p>}
  </div>);
}

export function NewsletterSignupSuccess(props:any)
{
  return(<div className="inline-flex px-2 md:px-8 py-2 md:py-4 my-2 bg-darkShadow border-dark max-w-3xl text-center flex-col justify-around items-center ">
    <p className="my-1 text-base font-semibold text-center text-secondary">
      Thanks for Joining!
    </p>
    <p className="my-1 text-base text-center text-light px-8">
      Be sure to add "newsletter@notoros.io" to your email's safe sender list to ensure you always receive updates from Notoros, Inc.
    </p>
  </div>);
}

export function NewsletterSignupError(props:any)
{
  const { error="" } = props;
  return(<div className="inline-flex px-2 md:px-8 py-2 md:py-4 my-2 bg-darkShadow border-dark max-w-3xl text-center flex-col justify-around items-center ">
    <p className="my-1 text-base font-semibold text-center text-red-500">
      Error during Newsletter Signup
    </p>
    <p className="my-1 text-base text-center text-light px-8">
      An error occurred when trying to sign up for the NSiders Newsletter. Please try again (later?).
    </p>
    {error.length > 0?<p className="my-1 text-sm text-center text-red-600 px-8">
      {error}
    </p>:null}
  </div>);
}