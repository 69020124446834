import React, { PropsWithChildren, ReactElement, useState } from "react";
import { ContactUsForm, ContactUsFormProps } from "./ContactUsForm";
import { SectionTitle } from "../../components/layout/section/SectionTitle";
import LeadForm from "./Contact_Forms/LeadForm";
import WebContactForm from "./Contact_Forms/WebContactForm";
import BusinessLeadForm from "./Contact_Forms/BusinessLeadForm";
import MediaInquiryLeadForm from "./Contact_Forms/MediaInquiryLeadForm";
export interface ContactPageProps extends PropsWithChildren {
  contactUsFunction: (email: string, name:string, message:string)=>Promise<any>
}

// TODO: Add Notification (e.g. snackbar) that indicates the result of the form submission
export const ContactPage = (props:ContactPageProps): ReactElement => {
  const { contactUsFunction=async()=>"" } = props;

  const [contactComplete, setContactComplete] = useState<boolean>(false);
  const [contactError, setContactError] = useState<string>("");
  const [values, setValues] = useState({
    name: "",
    type: "",
    email: "",
    message: "",
  });

  const handleChange = (name: any, value: string) => {
    setValues({ ...values, [name]: value });
  };

  function onSubmit()
  {
    return(contactUsFunction(values.email, values.name, values.message).then((e?:string)=>{
      if(typeof(e)=='string')
      {
        setContactComplete(true);
        if(e.length > 0)
        {
          setContactError(e);
        }
      }
    }));
  }

 

  return (
    <div className={"flex justify-center p-16 bg-dark text-secondary"}>
    <div className={"w-full max-w-5xl"}>
      <SectionTitle useDivider={true} title={"Contact Us"}/>
      {!contactComplete ? (<div className="mt-8">
        <React.Fragment>
          {/* <ContactUsPageDemographics/> */}
          <ContactUsFormWithGraphic values={values} handleChange={handleChange} onSubmit={onSubmit} />
        </React.Fragment></div>
      ) : (contactError.length > 0 ? <ConactUsError error={contactError} /> : <ContactUsSuccess />)}
    </div>
  </div>
  );
};

export function ContactUsSuccess(props:any)
{
  return(<div className="text-center">
    <h3 className="text-2xl text-secondary text-center my-2">Thanks!</h3>
    <p className="text-lg text-light text-center">We've received your message and will reach out when we are available.</p>
  </div>)
}

export function ConactUsError(props:any)
{
  const { error="" } = props;
  return(<div className="inline-flex px-2 md:px-8 py-2 md:py-4 my-2 max-w-3xl text-center flex-col justify-around items-center ">
    <p className="my-1 text-base font-semibold text-center text-red-500">
      Error during Contact Form Submission
    </p>
    <p className="my-1 text-base text-center text-light px-8">
      An error occurred when trying to submit the Contact form data. Please try again (later?).
    </p>
    {error.length > 0?<p className="my-1 text-sm text-center text-red-600 px-8">
      {error}
    </p>:null}
  </div>);
}

export function ContactUsPageDemographics(props:{})
{
  return(<div className="flex flex-col content-center justify-between bg-dark text-secondary flex-wrap">
    <ContactPageDemographic heading={"For Developers And Businesses"} content={<React.Fragment>
      Notoros is looking for developers and businesses that want to build new dApps or migrate their existing dApps to our network. To get more information, contact <a href="mailto:partners@notoros.io" className=" underline text-secondary">partners@notoros.io</a>
    </React.Fragment>}/>
    <ContactPageDemographic heading={"For Everyone Else"} content={"Don’t fit into one of the categories above? Fill out this form or go to our socials to contact us or, if you want, just to say hello!"}/>
  </div>);
}

export function ContactUsFormWithGraphic(props: ContactUsFormProps ) {
  const [activeButton, setActiveButton] = useState('webContactForm');

  function handleClick(buttonName: string) {
    setActiveButton(buttonName);
  }

  return (
    <div className="flex flex-row justify-evenly relative">
      <div className={"grow basis-1/2"}>
        <div className="">
          <button
            className={`border-2 border-secondary text-secondary rounded-lg py-2 px-4 m-2 ${activeButton === 'webContactForm' ? 'bg-secondary text-white' : ''}`}
            onClick={() => handleClick('webContactForm')}
          >
            General
          </button>
          <button
            className={`border-2 border-secondary text-secondary rounded-lg py-2 px-4 m-2 ${activeButton === 'LeadForm' ? 'bg-secondary' : ''}`}
            onClick={() => handleClick('LeadForm')}
          >
            Developer
          </button>
          <button
            className={`border-2 border-secondary text-secondary rounded-lg py-2 px-4 m-2 ${activeButton === 'businessLeadForm' ? 'bg-secondary text-white' : ''}`}
            onClick={() => handleClick('businessLeadForm')}
          >
            Business
          </button>
          <button
            className={`border-2 border-secondary text-secondary rounded-lg py-2 px-4 m-2 ${activeButton === 'mediaLeadForm' ? 'bg-secondary text-white' : ''}`}
            onClick={() => handleClick('mediaLeadForm')}
          >
            Media
          </button>
        </div>
        <div className="">
          {activeButton === 'webContactForm' ? <WebContactForm onSubmit={() => {return true}} /> : null}
          {activeButton === 'LeadForm' ? <LeadForm /> : null}
          {activeButton === 'businessLeadForm' ? <BusinessLeadForm /> : null}
          {activeButton === 'mediaLeadForm' ? <MediaInquiryLeadForm /> : null}
        </div>
      </div>
    </div>
  );
}

export type StringOrElement = string | React.ReactElement | React.ReactElement[];

export function ContactPageDemographic(props:{heading:string, content:StringOrElement})
{
  const { heading, content } = props;
  return(<div className="my-4 mx-0">
    <SectionTitle title={heading} size="normal"/>
    <p className="text-light max-w-2xl">{content}</p>
  </div>)
}
export default ContactPage;