import React from "react";
import "./newsletterForm.css";

interface Props {
  onSubmit?: () => boolean;
}

function validateEmail5180905000001676006() {
  var form = (document.forms as any)["WebToLeads5180905000001676006"];
  var emailFld = form.querySelectorAll("[ftype=email]");
  var i;
  for (i = 0; i < emailFld.length; i++) {
    var emailVal = emailFld[i].value;
    if (emailVal.replace(/^\s+|\s+$/g, "").length != 0) {
      var atpos = emailVal.indexOf("@");
      var dotpos = emailVal.lastIndexOf(".");
      if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
        alert("Please enter a valid email address. ");
        emailFld[i].focus();
        return false;
      }
    }
  }
  return true;
}

function checkMandatory5180905000001676006() {
  "use strict";
  const mndFields = ["Last Name", "Email"];
  const fldLangVal = ["Last\x20Name", "Email"];
  for (let i = 0; i < mndFields.length; i++) {
    const fieldObj = (document.forms as any)["WebToLeads5180905000001676006"][
      mndFields[i]
    ];
    if (fieldObj) {
      if (fieldObj.value.trim().length === 0) {
        if (fieldObj.type === "file") {
          alert("Please select a file to upload.");
          fieldObj.focus();
          return false;
        }
        alert(fldLangVal[i] + " cannot be empty.");
        fieldObj.focus();
        return false;
      } else if (fieldObj.nodeName === "SELECT") {
        if (fieldObj.options[fieldObj.selectedIndex].value === "-None-") {
          alert(fldLangVal[i] + " cannot be none.");
          fieldObj.focus();
          return false;
        }
      } else if (fieldObj.type === "checkbox") {
        if (!fieldObj.checked) {
          alert("Please accept " + fldLangVal[i]);
          fieldObj.focus();
          return false;
        }
      }
      try {
        if (fieldObj.name === "Last Name") {
          let name = fieldObj.value;
        }
      } catch (e) {}
    }
  }
  if (!validateEmail5180905000001676006()) {
    return false;
  }
  document
    .querySelector(".crmWebToEntityForm .formsubmit")
    ?.setAttribute("disabled", "true");
}

function tooltipShow5180905000001676006(el: any) {
  var tooltip = el.nextElementSibling;
  var tooltipDisplay = tooltip.style.display;
  if (tooltipDisplay == "none") {
    var allTooltip = document.getElementsByClassName("zcwf_tooltip_over");
    for (let i = 0; i < allTooltip.length; i++) {
      (allTooltip[i] as HTMLElement).style.display = "none";
    }
    tooltip.style.display = "block";
  } else {
    tooltip.style.display = "none";
  }
}

function NewsLetterSignupForm(props: Props) {
  const {} = props;

  return (
    <div
      id="crmWebToEntityForm"
      className="zcwf_lblLeft crmWebToEntityForm bg-inherit text-secondary"
      style={{ maxWidth: "600px" }}
    >
      <form
        action="https://crm.zoho.com/crm/WebToLeadForm"
        name={"WebToLeads5180905000001676006"}
        method="POST"
        onSubmit={() => checkMandatory5180905000001676006()}
        accept-charset="UTF-8"
      >
        <input
          type="text"
          style={{ display: "none" }}
          name="xnQsjsdp"
          value="fcc064e62621ccb52bdb6049dfd697fdcd543f0cd11bc3e65003c960441fe458"
        />
        <input type="hidden" name="zc_gad" id="zc_gad" value=""></input>
        <input
          type="text"
          style={{ display: "none" }}
          name="xmIwtLD"
          value="13d18d44710e7ba48b9ab18012bdf332435a4f56add43c46b2992620c498080e"
        />
        <input
          type="text"
          style={{ display: "none" }}
          name="actionType"
          value="TGVhZHM="
        />
        <input
          type="text"
          style={{ display: "none" }}
          name="returnURL"
          value="https&#x3a;&#x2f;&#x2f;notoros.io"
        />
        <div className="zcwf_row">
          <div className="zcwf_col_lab">
            <label htmlFor="First_Name">First Name</label>
          </div>
          <div className="zcwf_col_fld">
            <input
              type="text"
              id="First_Name"
              name="First Name"
              maxLength={40}
            />
            <div className="zcwf_col_help"></div>
          </div>
        </div>
        <div className="zcwf_row">
          <div className="zcwf_col_lab">
            <label htmlFor="Last_Name">
              Last Name<span>*</span>
            </label>
          </div>
          <div className="zcwf_col_fld">
            <input type="text" id="Last_Name" name="Last Name" maxLength={80} />
            <div className="zcwf_col_help"></div>
          </div>
        </div>
        <div className="zcwf_row">
          <div className="zcwf_col_lab">
            <label htmlFor="Email">
              Email<span>*</span>
            </label>
          </div>
          <div className="zcwf_col_fld">
            <input type="text" id="Email" name="Email" maxLength={100} />
            <div className="zcwf_col_help"></div>
          </div>
        </div>
        <div className="zcwf_row">
          <div className="zcwf_col_lab"></div>
          <div className="zcwf_col_fld">
            <input
              type="submit"
              id="formsubmit"
              className="formsubmit zcwf_button"
              value="Submit"
              title="Submit"
            />
            <input
              type="reset"
              className="zcwf_button"
              name="reset"
              value="Reset"
              title="Reset"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default NewsLetterSignupForm;
