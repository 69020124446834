import React, { MouseEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";

export interface CarouselItem {
  title: string | React.ReactElement | React.ReactElement[],
  image?: string,
  route?: string,
};
export function LargeCarousel(props:{items: Array<CarouselItem>})
{
  const { items=[] } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(()=>{
    setCurrentIndex(0);
  }, [items.length]);

  return(<div className="relative overflow-hidden whitespace-nowrap my-4 md:my-8">
    <CarouselNav position="left" visible={currentIndex > 0} onClick={()=>setCurrentIndex((i:number)=>{return(i-1)})}/>
    {
      items.map((item, key:number) => {
        return(<LargeCarouselItem index={currentIndex} title={item.title} route={item.route} image={item.image} key={key}/>)
      })
    }
    <CarouselNav position="right" visible={currentIndex < items.length - 1} onClick={()=>setCurrentIndex((i:number)=>{return(i+1)})}/>
  </div>)
}

export function CarouselNavButton(props:any)
{
  const { onClick = ()=>true, children } = props;
  function handleClick(e:MouseEvent)
  {
    e.preventDefault();
    e.stopPropagation();
    onClick();
    return;
  }
  return(<button className="px-2 pb-1 md:text-lg font-extrabold bg-gray-500 rounded text-secondary" onClick={handleClick}>{children}</button>)
}

export function CarouselNav(props:any)
{
  const { visible=true, onClick=()=>true, position="left" } = props;
  const buttonIcon = position=="left"?"<":">";
  const className=`${position=="left"?"left-0":"right-0"} px-1 absolute h-full inline-flex flex-col justify-center top-1/2 -translate-y-1/2 z-10`;
  return(<span className={className}>
    {visible?<CarouselNavButton onClick={onClick}>{buttonIcon}</CarouselNavButton>:null}
  </span>)
}

export function LargeCarouselItemContainer(props:any)
{
  const { image ="", children, index=0 } = props;
  let className = "";
  const percentTranslation = (100*index).toString();

  return(<div className={"inline-block w-full h-full bg-cover bg-center transition-transform "+className} style={{ transform:`translate(-${percentTranslation}%)`, backgroundImage: `url('${image}')` }}>
    <div className="w-full h-full text-center bg-gradient-to-b from-[rgba(125,125,125,0.4)] to-[rgba(0,0,0.9)] ">
      {children}
    </div>
  </div>)
}

export function LargeCarouselItemButton(props:any)
{
  const { route = "." } = props;
  return(<Link className="text-sm md:text-base uppercase font-semibold text-dark bg-vibrant rounded-2xl py-1 md:py-2 px-4 md:px-8 inline-block shadow-sm hover:text-white hover:cursor-pointer" to={route}>Read More</Link>)
}

export function LargeCarouselTitleOverlay(props:any)
{
  const { title } = props;
  return(<h2 className="text-sm sm:text-lg md:text-4xl text-light font-extrabold">{title}</h2>);
}

export function LargeCarouselItem(props:any)
{
  const { title="", route="", image="", index=0 } = props;
  return(<LargeCarouselItemContainer image={image} index={index}>
    <div className="py-6 sm:py-12 md:py-24">
      <LargeCarouselTitleOverlay title={title}/>
      <div className="py-4 md:py-12">
        <LargeCarouselItemButton route={route}/>
      </div>
    </div>
  </LargeCarouselItemContainer>);
}

export default LargeCarousel;