import React, {useEffect, useState} from 'react';

export interface TypewritingBannerProps {
  words: string
};

export function TypewritingBanner(props:TypewritingBannerProps)
{
  const { words="" } = props;
  return(
  <div className={'p-2  w-full text-md text-secondary text-center flex flex-row justify-center'} style={{fontFamily: "Courier New"}}>
    <TypewritingText words={words} className={"text-secondary border-r-secondary"}/>
  </div>);
}

export interface TypewritingTextProps extends TypewritingBannerProps {
  className ?: string,
  speed?: "fast"|"normal"|"slow"
}
export function TypewritingText(props:TypewritingTextProps)
{
  const { words="", className="", speed="fast" } = props;
  // const [renderedWords, setRenderedWords]=useState<string>(words);
  let animation = "animate-typing-text";
  switch(speed)
  {
    case "fast":
    {
      animation = "animate-typing-text-fast";
      break;
    }
    case "normal":
    {
      animation = "animate-typing-text";
      break;
    }
    case "slow":
    {
      animation = "animate-typing-text-slow";
      break;
    }
  }
  // useEffect(()=>{
  //   setRenderedWords(words);
  // },[words])
  return(
    <div key={`${words}${Math.random()}`} className={'w-full text-md flex flex-row justify-start'} >
      <div className={'flex flex-row justify-start py-2 overflow-hidden '+animation}>
        <div  className={'py-2 overflow-hidden whitespace-nowrap my-0 leading-very-tight tracking-widest animate-typing-carat border-r-[.3em] border-solid border-y-0 border-l-0 '+className} >
        {/* Typewriter Class*/}
        {
          words.split(" ").map((w,i)=>{
            return(<span key={i}>
              <p className='text-xl md:text-4xl inline font-semibold'>{w}</p><p className={"text-lg md:text-xl inline"}> </p>
            </span>)
          })
        }
        </div>
      </div>
    </div>);
}

export interface TypewritingWordsProps extends TypewritingBannerProps {
  className ?: string,
}
function TypingWords(props:TypewritingWordsProps)
{
  const { words="", className="" } = props;
  return(<div className={'py-2 overflow-hidden text-center whitespace-nowrap my-0 mx-auto leading-very-tight tracking-widest animate-typing-carat border-r-[.3em] border-solid border-y-0 border-l-0 '+className} >
  {/* Typewriter Class*/}
  {
     words.split(" ").map((w,i)=>{
      return(<span key={i}>
        <p className='text-4xl inline font-semibold'>{w}</p><p className={"text-xl inline"}> </p>
      </span>)
    })
  }
  </div>);
}

export function HeroBanner(props:any)
{
  const { content="Realize the Vision of Blockchain" } = props;
  return(<h1 className={"w-full text-center block text-5xl text-light mb-8"}>{content}</h1>)
}