import React, { SyntheticEvent, useEffect, useRef } from 'react';
import { setUpDocumentListener } from '../../utils/documentClickListener';

export function Modal(props:any)
{
  const { open = false, forced=false, onClose=()=>true, children=[], className="" } = props;
  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  function toggleModal (o:boolean)
  {
    if(dialogRef.current)
    {
      if(o && !dialogRef.current.open)
      {
        dialogRef.current.showModal();
      }
      else
      {
        dialogRef.current.close();
      }
    }
  }
  useEffect(()=>{
    if(!forced)
    {
      return(setUpDocumentListener(contentRef, ()=>toggleModal(false)));
    }
    else
    {
      return;
    }
  },[]);
  function handleCancel(e: SyntheticEvent)
  {
    if(forced)
    {
      if(typeof(e.preventDefault)=='function'){e.preventDefault();}
    }
    return;
  }
  useEffect(()=>{
    if(open)
    {
      toggleModal(true);
    }
  },[open])
  return(<dialog onCancel={handleCancel} className="fixed top-0 bottom-0 p-0 bg-transparent backdrop:bg-black backdrop:bg-opacity-50" ref={dialogRef} onClose={()=>{
    onClose();
    return;
  }}>{open?<div ref={contentRef} className={className}>{children}</div>:null}
  {/* Container div allows window listener to unmount */}
  </dialog>);
}