export const emailRegex =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function createSubscribeNewsletterRequestFunction(endpoint="http://localhost:3003")
{
  let newsletterApi:string = "/newsletter";
  if(typeof(endpoint)=='string')
  {
    newsletterApi = endpoint;
  }
  else
  {
    newsletterApi = process.env.NODE_ENV === "production" ? (process.env.REACT_APP_PROD_NEWSLETTER_API_URL||"/") : "/";
  }
  return((email:string)=>{
    console.log(`Subscribing to newsletter with email [${email}] (url: [${newsletterApi}])`);
    return(fetch(newsletterApi, {
      method: "POST",
      body: JSON.stringify({email: email}),
      headers: {
        'Content-Type': 'application/json'
       },
    }).then((response) => {
      console.log(response);
      if(response.status!==200)
      {
        return(`Error with status ${response.status}`);
      }
      else return("");
    }).catch(e=>{
      console.error(e);
      return("API Error. Check console output for details");
    }));
  });
}

export function createContactUsRequestFunction(endpoint="http://localhost:3003")
{
  let newsletterApi = "/contact";
  if(typeof(endpoint)=='string')
  {
    newsletterApi = endpoint;
  }

  return((email:string, name:string, message:string)=>{
    console.log(`Submit ContactUs Form with email [${email}][${name}][${message}] (url: [${newsletterApi}])`);
    return(fetch(newsletterApi, {
      method: "POST",
      body: JSON.stringify({email, name, message}),
      headers: {
        'Content-Type': 'application/json'
       },
    }).then((response) => {
      if(response.status!==200)
      {
        return(`Error with status ${response.status}`);
      }
      else return("");

    }).catch(e=>{
      console.error(e);
      return("API Error. Check console output for details");
    }));
  });
}

export const sanitizeForClassName = (s:string) => s.replace('/', '-').replace(' ', '-').replace('\'', '-').replace('"', '-').toLowerCase();


